<!-- C端用户取消免费用时间设置弹窗 -->
<template>
  <el-dialog
      width="40%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'C端用户取消免费用时间设置':'C端用户取消免费用时间设置'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="170px">
      <el-row>
        <el-col :span="24" style="margin-top: 7px;">
          <el-form-item label="免费用时长（分钟）：">
            <el-input type="number" v-model="form.figure" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="取消时收费标准（%）：">
            <el-input type="number" v-model="form.figure" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import {audit, disqualification} from "@/api/orderModule";

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

    };
  },
  watch: {

  },

  computed: {

  },

  mounted() {
    if (this.data) {
      console.log(this.data)
      this.form = {};
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }

  },
  methods: {

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          // 审核合格
          if(this.type == 'pass'){
            let where = {
              odd: this.data.order_id,
              repair_thread_price: this.Repairthread,
              other: this.form.other,
              repair_thread: this.repair_thread,
            }
            audit(this.data.id,where).then(res => {
              if(res.code == 200){
                this.loading = true;
                this.updateVisible(false);
                this.$emit('done');
                this.loading = false;
                this.$message.success(res.msg);
              }else {
                this.loading = false;
                this.$message.error(res.msg)
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg)
            })
          }else {
            let where = {
              odd: this.data.order_id,
              repair_thread_price: this.Repairthread,
              other: this.form.other,
              repair_thread: this.repair_thread,
            }
            disqualification(this.data.id,where).then(res => {
              if(res.code == 200){
                this.loading = true;
                this.updateVisible(false);
                this.$emit('done');
                this.loading = false;
                this.$message.success(res.msg);
              }else {
                this.loading = false;
                this.$message.error(res.msg)
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg)
            })
          }


        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },



  }
}
</script>

<style scoped lang="scss">

</style>
